import React from "react";
import "./Navbar.css";
import logoMark from "../../image/Feurlogomark.svg";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div className="navBar">
      <div className="navRightSide">
      <Link to="/"><img src={logoMark} alt="" className="logomarkImg"/></Link>
      </div>
      <div className="navLeftSide">
        {/* <h3 className="navAboutTxt"> About </h3> */}
        <Link to="/waitlist"> <button className="joinWaitBtn">Join the waitlist</button></Link>
      </div>
    </div>
  );
};

export default Navbar;
