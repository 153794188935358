import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./JoinWaitList.css";
import fuerLogo from "../../image/darkfuerlogo.svg";
import cancel from "../../image/svg.svg";
import Navbar from "../navbar/Navbar";

const JoinWaitlist = () => {
  const [cancelX, setCancelX] = useState(false);
  const form = useRef();

  const handleCancel = () => {
    setCancelX(!cancelX);
  };

  //   const sendEmail = (e) => {
  //     e.preventDefault();

  //     emailjs
  //       .sendForm(
  //         "service_rzyderm",
  //         "template_8er7ade",
  //         form.current,
  //         "zj1It_baEanoeNAck"
  //       )
  //       .then(
  //         (result) => {
  //           console.log(result.text);
  //         },
  //         (error) => {
  //           console.log(error.text);
  //         }
  //       );
  //     e.target.reset();
  //   };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_2ycovuv", "template_ckxq2vx", form.current, {
        publicKey: "DOV4CNraJtcRPCJts",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <div className="Wishlist">
      <Navbar />
      <div className="joinWaitlist">
        <div className="joinWaitlistBox">
          <img src={fuerLogo} alt="" />
          <h1 className="joinWaitlistH">
            <span>Join</span> the <span>Waitlist!</span>
          </h1>
          <form
            action=""
            className="joinWaitlistForm"
            ref={form}
            onSubmit={sendEmail}
          >
            <input
              name="user_name"
              type="text"
              placeholder="Name"
              className="joinWaitlistFormInput"
            //   required
            />
            <input
              type="email"
              placeholder="Email"
              className="joinWaitlistFormInput"
              required
              name="user_email"
            />
            <button className="joinWaitlistFormBtn" onClick={handleCancel} type="submit">
              Join Now
            </button>
          </form>
        </div>
      </div>
      <div className={cancelX ? "thankYouBox" : "thankYouBox thankYouBox-NX"}>
        <img src={cancel} alt="" className="cancel" onClick={handleCancel} />
        <img src={fuerLogo} alt="" className="joinFuerLogo" />
        <h1 className="thankYouTxt">
          Thank <span>You!</span>
        </h1>
      </div>
    </div>
  );
};

export default JoinWaitlist;
