import React, { useEffect } from "react";
import "./LandingPage.css";
import chaffeur from "../../image/Chauffeur.svg";
import Navbar from "../navbar/Navbar";
import group100 from "../../image/Group100.svg";
import groupSteps from "../../image/GroupSteps.svg";
import icon1 from "../../image/icon1.svg";
import icon2 from "../../image/icon2.svg";
import icon3 from "../../image/icon3.svg";
import icon4 from "../../image/icon4.svg";
import icon5 from "../../image/icon5.svg";
import icon6 from "../../image/icon6.svg";
import choose1 from "../../image/choose1.svg";
import choose2 from "../../image/choose2.svg";
import choose3 from "../../image/choose3.svg";
import choose4 from "../../image/choose4.svg";
import safety from "../../image/safety.svg";
import hand from "../../image/hand.svg";
import Footer from "../footer/Footer";
import Persona from "./Persona";
import { Link } from "react-router-dom";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="LandingPage">
      <div className="navDiv">
        <Navbar />
      </div>
      <div className="chafFeur">
        <div>
          <h1 className="hireTxt">Hire a Driver anytime, anywhere!</h1>
          <p className="getDriverTxt">
            Get a driver for your car whenever you need. No contracts, no
            commitments.
          </p>
          <Link to="/waitlist">
            {" "}
            <button className="joinWaitlistBtn">Join the waitlist</button>
          </Link>
        </div>
        <div>
          <img src={chaffeur} alt="" className="chaffeurImg" />
        </div>
      </div>
      {/* ABOUT US */}
      <div className="aboutUsDiv">
        <img src={group100} alt="" className="group100Img" />
        <p className="aboutUsTxt">
          Feur is a mobility platform company designed to solve common problems
          faced by car owners who can’t afford to hire full-time drivers.
          Whether it's for a daily commute, a quick errand, or a long-distance
          trip, Feur connects car owners with qualified drivers on-demand,
          anytime and anywhere. By offering flexible, reliable driving services,
          Feur makes owning a car more convenient and affordable. We’re here to
          bridge the gap between car owners and experienced drivers, providing
          freedom and convenience with just a few taps.
        </p>
      </div>
      {/* STEPS TO USING THE FEUR APP */}
      <div className="stepsDiv">
        <div className="stepTxtDiv">
          <h1>Steps to using Feur app</h1>
        </div>
        <div className="iconWrapper">
          <div className="icon1Div">
            <img src={icon1} alt="" className="iconImg" />
            <p className="iconTxt">Open the FEUR app and sign up</p>
          </div>
          <div className="icon2Div">
            <img src={icon2} alt="" className="iconImg" />
            <p className="iconTxt">
              Choose and confirm pick up and destination location
            </p>
          </div>
          <div className="icon3Div">
            <img src={icon3} alt="" className="iconImg" />
            <p className="iconTxt">
              Hire a driver who is mins away to take you in your car
            </p>
          </div>
          <div className="icon4Div">
            <img src={icon4} alt="" className="iconImg" />
            <p className="iconTxt">
              Choose and confirm the driver’s details on arrival.{" "}
            </p>
          </div>
          <div className="icon5Div">
            <img src={icon5} alt="" className="iconImg" />
            <p className="iconTxt">Onboard and arrive at destination</p>
          </div>
          <div className="icon6Div">
            <img src={icon6} alt="" className="iconImg" />
            <p className="iconTxt">The driver and ride both leave ratings</p>
          </div>
        </div>
        {/* <img src={groupSteps} alt="" className="groupStepsImg" /> */}
      </div>
      {/* WHY CHOOSE */}
      <div className="chooseBox">
        <div className="chooseTxtDiv">
          <h1>Why Choose Feur?</h1>
        </div>
        <div className="chooseWrapper">
          <img src={choose1} alt="" />
          <img src={choose2} alt="" />
          <img src={choose3} alt="" />
          <img src={choose4} alt="" />
        </div>
      </div>
      {/* SAFETY FEATURES */}
      <div className="safetyWrapper">
        <div className="safetyTxtDiv">
          <h1>Safety Features</h1>
        </div>
        <div className="learnMoreBox">
          <img src={safety} alt="" className="safetyImage" />
          <div>
            <h4 className="learnMoreHtxt">
              <span>We’re committed</span> to keeping <span>you safe</span>
            </h4>
            <p className="learnMorePtxt">
              As a company that cares about your safety, there are provisions
              and guidelines to ensure and guarantee your safety{" "}
            </p>
            <Link to="/waitlist">
              {" "}
              <button className="learnMoreBtn">Learn More</button>
            </Link>
          </div>
        </div>
      </div>
      {/* PERSONAL */}
      <Persona />
      {/* JOIN THE WISHLIST */}
      <div className="wishList">
        <div className="wishlistTxtDiv">
          <h1 className="wishlistTxt">Join The Waitlist</h1>
        </div>
        <div className="wishlistBox">
          <h3 className="quickRequestTxt">
            Quick request, driver at your door in no time
          </h3>
          <p className="avalaibleTxt">Available for iOS and Android services</p>
          <Link to="/waitlist">
            <button className="whiteWaitlistBtn">Join the waitlist</button>
          </Link>
          <img src={hand} alt="" className="handImage" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
