import React from "react";
import "./Footer.css";
import fuerFooter from "../../image/feurfooter.svg";
import fuerLight from "../../image/fuerlight.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer">
      <img src={fuerFooter} alt="" className="fuerFooterImg" />
      <div>
        <h2 className="footerH2">Company</h2>
        <p className="footerP">About us</p>
        <p className="footerP">Rides</p>
        <p className="footerP">Safety</p>
        <h2 className="footerH2">Partner</h2>
        <p className="footerP">Sign up as a driver</p>
        <p className="footerP">Careers</p>
        <h2 className="footerH2">Contact Us</h2>
        <p className="footerP">feurtechnologies@gmail.com</p>
        <Link to="/waitlist"><button className="footerJoinBtn">Join our wishlist</button></Link>
      </div>
      <div className="fuerLightDiv">
        <img src={fuerLight} alt="" className="fuerLightImg" />
        <p className="needHelpTxt">Need Help?</p>
      </div>
      <p className="copyrightTxt">Copyright 2024 Feur. All Rights Reserved</p>
    </div>
  );
};

export default Footer;
