import React from "react";
import "./Persona.css";
import tunde from "../../image/Tunde.svg";
import debo from "../../image/Deborah.svg";
import convo from "../../image/convo.svg";

const Persona = () => {
  return (
    <div className="Persona">
      <div className="safetyTxtDiv">
        <h1>Persona</h1>
      </div>
      <div>
        <div className="personalBox">
          <img src={tunde} alt="" className="personaImg" />
          <h2 className="personaNameTxt">Tunde James</h2>
          <span className="personaAgeTxt">
            39 years Lagos, Nigeria Medical Doctor
          </span>
          <span className="convoBox">
            <img src={convo} alt="" />
            <p className="personaConvoTxt">
              I need a reliable and secure driver who will always come at any
              time.
            </p>
          </span>
          <p className="personaAboutTxt">
            5 years as a medical doctor, he works for 50 hours per week.
          </p>
          <p className="personaAboutTxt">
            Activities: Playing basketball, video games and card games.
          </p>
          <div className="needsAndProblem">
            <div>
              <h5 className="needsAndProblemTxt">Needs and goals</h5>
              <ul>
                <li className="needsAndProblemLi">
                  User-friendly interface for hiring drivers.
                </li>
                <li className="needsAndProblemLi">Fair pricing</li>
                <li className="needsAndProblemLi">Easy navigation</li>
                <li className="needsAndProblemLi">
                  Maximum security assurance
                </li>
              </ul>
            </div>
            <div>
              <h5 className="needsAndProblemTxt">Problems and pain points</h5>
              <ul>
                <li className="needsAndProblemLi">Expensive driver salary</li>
                <li className="needsAndProblemLi">Tiredness from workload</li>
                <li className="needsAndProblemLi">Drowsiness while driving</li>
                <li className="needsAndProblemLi">
                  Inability to drive at night
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* SECOND PERSONAL */}
        <div className="personalBox">
          <img src={debo} alt="" className="personaImg" />
          <h2 className="personaNameTxt">Deborah Nwachukwu</h2>
          <span className="personaAgeTxt">
            28 years Lagos, Nigeria Journalist
          </span>
          <span className="convoBox">
            <img src={convo} alt="" />
            <p className="personaConvoTxt">
              I need a secure driver who can come anywhere I am and on time.
            </p>
          </span>
          <p className="personaAboutTxt">
            3 years as a journalist, she works for 30 hours per week.
          </p>
          <p className="personaAboutTxt">
            Activities: Reading, swimming, driving to work
          </p>
          <div className="needsAndProblem">
            <div>
              <h5 className="needsAndProblemTxt">Needs and goals</h5>
              <ul>
                <li className="needsAndProblemLi">
                  User-friendly interface for hiring drivers.
                </li>
                <li className="needsAndProblemLi">Professional drivers</li>
                <li className="needsAndProblemLi">
                  Maximum security assistance
                </li>
              </ul>
            </div>
            <div>
              <h5 className="needsAndProblemTxt">Problems and pain points</h5>
              <ul>
                <li className="needsAndProblemLi">
                  Driving home from work at late hours.
                </li>
                <li className="needsAndProblemLi">
                  Goes to far distances due to the nature of her job.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Persona;
